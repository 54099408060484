var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ui-h',{attrs:{"small":"","color":"black"}},[_vm._v(" My Details ")]),_c('ui-p',{attrs:{"color":"black"}},[_vm._v(" Please select from the options below. ")]),_c('ui-p',[_c('ui-button',{attrs:{"primary":""},on:{"tapped":_vm.showUpdateEmailAddressModal}},[_vm._v(" Update Email Address ")])],1),_c('ui-p',[_c('ui-button',{attrs:{"primary":""},on:{"tapped":_vm.showUpdatePasswordModal}},[_vm._v(" Update Password ")])],1),_c('modal',{attrs:{"height":"auto","scrollable":"","adaptive":"","name":"update-email-address"}},[_c('div',{staticClass:"modal"},[_c('ui-h',{attrs:{"color":"black"}},[_vm._v("Update Email Address")]),_c('ui-p',{attrs:{"color":"black"}},[_vm._v("To update your email address please enter your current password and new email address")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.reauthenticateEmailAddress)}}},[_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Current Email Address")]),_c('ValidationProvider',{attrs:{"name":"Current Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateEmailCurrent),expression:"updateEmailCurrent"}],staticClass:"form-input",attrs:{"type":"text"},domProps:{"value":(_vm.updateEmailCurrent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.updateEmailCurrent=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("New Email Address")]),_c('ValidationProvider',{attrs:{"name":"New Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateEmailNew),expression:"updateEmailNew"}],staticClass:"form-input",attrs:{"type":"text"},domProps:{"value":(_vm.updateEmailNew)},on:{"input":function($event){if($event.target.composing){ return; }_vm.updateEmailNew=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateEmailPassword),expression:"updateEmailPassword"}],staticClass:"form-input",attrs:{"type":"password"},domProps:{"value":(_vm.updateEmailPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.updateEmailPassword=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('ui-p',[_c('ui-button',{attrs:{"primary":"","loading":_vm.isUpdatingEmailAddress}},[_vm._v("Update Email")])],1)],1)]}}])}),_c('ui-button',{attrs:{"inline":"","secondary":""},on:{"tapped":function($event){return _vm.$modal.hide('update-email-address')}}},[_vm._v("Close")])],1)]),_c('modal',{attrs:{"height":"auto","scrollable":"","adaptive":"","name":"update-password"}},[_c('div',{staticClass:"modal"},[_c('ui-h',{attrs:{"color":"black"}},[_vm._v("Update Password")]),_c('ui-p',{attrs:{"color":"black"}},[_vm._v(" Your password must be at least 8 characters and one uppercase character and one number/special character. ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.reauthenticatePassword)}}},[_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Current Email")]),_c('ValidationProvider',{attrs:{"name":"Current Email Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updatePasswordEmail),expression:"updatePasswordEmail"}],staticClass:"form-input",attrs:{"type":"text"},domProps:{"value":(_vm.updatePasswordEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.updatePasswordEmail=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Current Password")]),_c('ValidationProvider',{attrs:{"name":"Current Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updatePasswordCurrent),expression:"updatePasswordCurrent"}],staticClass:"form-input",attrs:{"type":"text"},domProps:{"value":(_vm.updatePasswordCurrent)},on:{"input":function($event){if($event.target.composing){ return; }_vm.updatePasswordCurrent=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("New Password")]),_c('ValidationProvider',{attrs:{"name":"New Password","rules":{ required: true, regex: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updatePasswordNew),expression:"updatePasswordNew"}],staticClass:"form-input",attrs:{"type":"password"},domProps:{"value":(_vm.updatePasswordNew)},on:{"input":function($event){if($event.target.composing){ return; }_vm.updatePasswordNew=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('ui-p',[_c('ui-button',{attrs:{"inline":"","primary":"","loading":_vm.isUpdatingPassword}},[_vm._v("Update Password")])],1)],1)]}}])}),_c('ui-button',{attrs:{"inline":"","secondary":""},on:{"tapped":function($event){return _vm.$modal.hide('update-password')}}},[_vm._v("Close")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }