<template>
    <div>

      <ui-h
        small
        color="black"
      >
        My Details
      </ui-h>

      <ui-p
        color="black"
      >
        Please select from the options below.
      </ui-p>

      <ui-p>
        <ui-button
          primary
          v-on:tapped="showUpdateEmailAddressModal"
        >
          Update Email Address
        </ui-button>
      </ui-p>

      <ui-p>
        <ui-button
          primary
          v-on:tapped="showUpdatePasswordModal"
        >
          Update Password
        </ui-button>
      </ui-p>

      <modal height="auto" scrollable adaptive name="update-email-address">
        <div class="modal">
          <ui-h color="black">Update Email Address</ui-h>
          <ui-p color="black">To update your email address please enter your current password and new email address</ui-p>
          <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(reauthenticateEmailAddress)">

            <div class="form-wrapper">
              <label>Current Email Address</label>
              <ValidationProvider name="Current Email Address" rules="required|email" v-slot="{ errors }">
                <input class="form-input" v-model="updateEmailCurrent" type="text">
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-wrapper">
              <label>New Email Address</label>
              <ValidationProvider name="New Email Address" rules="required|email" v-slot="{ errors }">
                <input class="form-input" v-model="updateEmailNew" type="text">
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-wrapper">
                <label>Password</label>
                <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                <input class="form-input" v-model="updateEmailPassword" type="password">
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <ui-p>
              <ui-button primary :loading="isUpdatingEmailAddress">Update Email</ui-button>
            </ui-p>
          </form>
        </ValidationObserver>

        <ui-button inline secondary v-on:tapped="$modal.hide('update-email-address')">Close</ui-button>
        </div>
      </modal>

      <modal height="auto" scrollable adaptive name="update-password">
        <div class="modal">
          <ui-h color="black">Update Password</ui-h>
          <ui-p
            color="black"
          >
            Your password must be at least 8 characters and one uppercase character and one number/special character.
          </ui-p>
          <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(reauthenticatePassword)">

            <div class="form-wrapper">
              <label>Current Email</label>
              <ValidationProvider name="Current Email Address" rules="required" v-slot="{ errors }">
                <input class="form-input" v-model="updatePasswordEmail" type="text">
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-wrapper">
              <label>Current Password</label>
              <ValidationProvider name="Current Password" rules="required" v-slot="{ errors }">
                <input class="form-input" v-model="updatePasswordCurrent" type="text">
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="form-wrapper">
                <label>New Password</label>
                <ValidationProvider name="New Password" :rules="{ required: true, regex: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }" v-slot="{ errors }">
                <input class="form-input" v-model="updatePasswordNew" type="password">
                <span class="form-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <ui-p>
              <ui-button inline primary :loading="isUpdatingPassword">Update Password</ui-button>
              
            </ui-p>
          </form>
        </ValidationObserver>

        <ui-button inline secondary v-on:tapped="$modal.hide('update-password')">Close</ui-button>
        </div>
      </modal>
   
    </div>
</template>

<script>
import firebase from 'firebase';
import { ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed, regex } from 'vee-validate/dist/rules';
import cross from '@/assets/svgs/cross.svg';

extend('required', required);
extend('email', email);
extend('regex', regex);
extend('confirmed', {
  ...confirmed,
  message: 'Passwords do not match'
});

export default {
    components: {
      ValidationObserver,
      cross
    },

    computed: {
      user() {
			  return this.$store.getters.getterUser
      }
		},

    data() {
      return {
        updateEmailCurrent: '',
        updateEmailNew: '',
        updateEmailPassword: '',
        isUpdatingEmailAddress: false,

        isUpdatingPassword: false,
        updatePasswordEmail: '',
        updatePasswordCurrent: '',
        updatePasswordNew: '',
      }
    },

    mounted() {
      this.updateEmailCurrent = this.user.email
      this.updatePasswordEmail = this.user.email
    },

    methods: {
      showUpdateEmailAddressModal() {
        this.$modal.show('update-email-address');
      },

      showUpdatePasswordModal() {
        this.$modal.show('update-password');
      },

      updatePassword() {
        this.isUpdatingPassword = true
      },

      async reauthenticatePassword() {
        const user = firebase.auth().currentUser;
        this.isUpdatingPassword = true

        let credential = firebase.auth.EmailAuthProvider.credential(
          this.updatePasswordEmail,
          this.updatePasswordCurrent
        );

        await user.reauthenticateWithCredential(credential)
        .then(() => {
          user.updatePassword(this.updatePasswordNew).then(() => {
            this.$modal.hide('update-password');
            this.$toasted.show('password updated', {
                position: 'bottom-center',
                theme: 'bubble'
            })
          }).catch((error) => {
            this.$toasted.show('password update failed', {
                position: 'bottom-center',
                theme: 'bubble'
            })
          });
        })
        .catch((error) => {
          this.$toasted.show(error.message, {
                position: 'bottom-center',
                theme: 'bubble'
          })
        })
        .finally(() => {
          this.isUpdatingPassword = false
        })
      },

      async reauthenticateEmailAddress() {
        const user = firebase.auth().currentUser;
        this.isUpdatingEmailAddress = true

        let credential = firebase.auth.EmailAuthProvider.credential(
          this.updateEmailCurrent,
          this.updateEmailPassword
        );

        await user.reauthenticateWithCredential(credential)
        .then(() => {
          user.updateEmail(this.updateEmailNew)
          .then(() => {
            // mutation to update email address
            this.$store.commit('mutationUpdateEmailAddress', this.updateEmailNew)
            this.$modal.hide('update-email-address');
            this.$toasted.show('Email Address updated', {
                position: 'bottom-center',
                theme: 'bubble'
            })
          }).catch((error) => {
            this.$toasted.show('Failed to update', {
                position: 'bottom-center',
                theme: 'bubble'
            })
          });
        })
        .catch((error) => {
          this.$toasted.show(error.message, {
                position: 'bottom-center',
                theme: 'bubble'
          })
        })
        .finally(() => {
          this.isUpdatingEmailAddress = false
        })
      }
    }

}
</script>